
import axios from 'axios';
export default {
    
    async createAdmins(params)  {
        return await axios.post(`admins/create` , params)
    },
    async createAdminsList(params)  {
        return await axios.post(`admins/create/list` , params)
    },
    async updateAdminsColumn(column , value , data)  {
        return await axios.put(`admins/update_list?${column}=${value}` , data)
    },
    async deleteAdminsList(list)  {
        return await axios.delete(`admins/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportAdmins(column , value)  {
        return await axios.get(`admins/report?${column}=${value}`)
    },
    async getAllAdmins()  {
        return await axios.get(`admins/all`)
    },
    async getOneAdmins(admin_id)  {
        return await axios.get(`admins/all/${admin_id}`)
    },
    async getAdminsByColumn(column , value)  {
        return await axios.get(`admins/filter?column=${column}&value=${value}`)
    },
    async deleteAdmins(admin_id)  {
        return await axios.delete(`admins/delete/${admin_id}`)
    },
    async updateAdmins(admin_id , params)  {
        return await axios.put(`admins/update/${admin_id}` , params)
    }
}